body,
#root,
.app {
  height: 100%;
  overflow: hidden;
  letter-spacing: 0.2rem;
  background-color: black;
  color: white;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: red !important;
}

/* Nav */

.nav-container {
  width: 100%;
  display: flex;
  flex-flow: wrap;
  color: white;
  letter-spacing: 0.8rem;
  text-shadow: 2px 2px 4px black;
  justify-content: center;
  font-size: 1.6rem !important;
}

.nav-container a {
  color: grey;
  text-decoration: none;
  padding: 0;
}

.nav-container a:hover {
  color: red;
}

.nav-active {
  color: white !important;
}

/* content */

.content {
  display: flex;
  justify-content: center;
}

.content-page {
  width: 600px;
  max-width: 900px;
}

.content-vertical {
  display: flex;
  flex-direction: column;
}

/* Home */

.home {
  height: inherit;
}

.home .img-container {
  background: url("./static/i/homepage.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: inherit;
  filter: brightness(40%);
}

.home .content {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
}

.home .content div {
  text-align: center;
  margin-bottom: 2rem;
}

.home .content div:nth-child(1) img {
  margin-top: 2rem;
  width: 80%;
  max-width: 700px;
}

.home .content div:nth-child(1) .nav-container div {
  width: auto;
}

.home .content div:nth-child(1) .nav-container {
  padding-left: 0.5rem;
  font-size: 1.2rem;
}

.home .content div:nth-child(2) img {
  width: 80%;
  max-width: 300px;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
}

.home .content div:nth-child(3) {
  width: 18rem;
  text-align: center;
}

.home .content .nav-container a {
  color: white;
}

@media only screen and (max-width: 441px) {
  .home .content .nav-container {
    height: 10rem;
    flex-direction: column;
    align-items: center;
    font-size: 1.8rem;
    font-weight: 700;
    justify-content: space-between;
  }

  .home .content .nav-container .nav-sep {
    display: none;
  }
}

.home .content .live-links {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  height: 48px;
}

.home .content .live-links img {
  margin-top: 1.6rem;
}

/* Page */

.page {
  display: flex;
  height: 100%;
  margin-bottom: 120px;
}

.page-img {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  min-width: 487px;
}

.page-img-right {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  min-width: 487px;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .page-img {
    min-width: 284px;
  }
}

@media only screen and (min-width: 444px) and (max-width: 767px) {
  .page-img {
    display: none;
  }
}

@media only screen and (max-width: 443px) {
  .page-img {
    min-width: 0;
  }
}

.page-center {
  width: 100vw;
  height: 100vh;
}

.page-center>div {
  height: 100vh;
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.page-text {
  text-align: center;
  width: 100%;
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
}

.page-text .menu {
  padding: 1rem;
  flex-shrink: 0;
}

.page-text .content {
  padding: 0 2rem 2rem 2rem;
  flex-grow: 1;
  min-height: 0;
  height: 100%;
  overflow: auto;
}

.page-text .content h2,
.page-text .content h3 {
  font-weight: 900;
  color: grey;
  letter-spacing: 0.4rem;
}

.page-logo {
  margin: 1rem;
  width: 100%;
  max-width: 300px;
}

/* pages */

.page-bio-img {
  background-image: url("./static/i/bio_art.png");
}

.page-media-img {
  background-image: url("./static/i/media_art.jpg");
}

.page-mixes-img {
  background-image: url("./static/i/mixes_art.jpg");
}

.page-contact-img {
  background-image: url("./static/i/contact_art.png");
}

/* section */
.section {
  margin: 2rem;
}

.section-table {
  margin: 2rem 0;
  text-align: left;
}

/* media */

.live-sets {
  text-align: center;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
}

.channels {
  min-height: 6rem;
}

.live-sets .stream {
  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.live-sets .stream label {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.live-sets .stream iframe {
  width: 400px;
}



/* forms */

.form {
  margin: 0 10%;
  text-align: left;
}

.form a:hover {
  color: white;
}

.form label {
  display: inline-block;
  margin-bottom: 0.3rem;
}

.form div {
  margin-bottom: 1rem;
}

.form input[type="text"],
textarea {
  width: 100%;
  background-color: #000;
  border: 1px silver solid;
  border-radius: 2px;
  color: #fff;
  font-size: 1rem;
  padding: 0.4rem;
}

.form textarea {
  resize: none;
  height: 5rem;
  font-size: 1.2rem;
}

/* Gallery */
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -1rem;
}

.gallery img {
  margin: 0 0 2rem 2rem;
}

.btn-bar {
  display: flex;
  flex-direction: row;
}

.btn-bar button:not(:last-child) {
  margin-right: 1rem;
}

.btn {
  cursor: pointer;
  font-size: 1rem;
  padding: 0.4rem 1.8rem;
  background-color: #000;
  color: #fff;
  border: 1px silver solid;
  border-radius: 2px;
}

.btn:hover {
  background-color: grey;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.social-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;
}

.social-links a {
  display: flex;
  align-items: center;
  height: 1.4rem;
}

.social-links a>img {
  margin-right: 0.75rem;
}

thead tr td {
  font-weight: 900;
}

.td-row {
  display: flex;
}

.redirect {
  text-align: center;
}

.redirect img:first-child {
  height: auto;
  max-width: 560px;
}

.redirect img:last-child {
  height: 50px;
  max-width: 300px;
}

@media only screen and (max-width: 767px) {
  .redirect img:last-child {
    max-width: 192px;
  }
}